body {
  background: url("https://files.gitbook.com/v0/b/gitbook-x-prod.appspot.com/o/spaces%2F9lwwVkwKv5wFUqVdkbTX%2Fuploads%2FU6LGuE40dnRiUDalAU1w%2FROM%20Servent%2011.png?alt=media&token=ebf32d0f-710d-4b43-b32b-2a54085d5f8c") center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: "Angon";
  src: local("Angon"),
    url("./fonts/Angon.ttf") format("truetype");
  font-weight: normal;
}